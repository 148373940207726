<template>
  <div class="container-conteudo">
    <div class="conteudo-interno">
      <div class="nova-comunicacao-container">
        <h2>Detalhes de {{ usuario.nome }}</h2>

        <tabs>
          <tab name="Dados Cadastrais" :selected="true">
            <form @submit.prevent class="formulario-container">

              <div class="d-md-flex">
                <div class="formulario-item">
                  <label for="cpf" class="formulario-item-label">CPF</label>
                  <input type="text" v-model="usuario.cpf" v-mask="'###.###.###-##'" name="cpf"
                    :class="{ 'invalid-field': verificarSeHaItem('cpf', this.errors, 'field') }" />
                  <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('cpf', this.errors, 'field')">
                    {{ verificarItemArray('cpf', this.errors, 'field').message }}
                  </p>
                </div>

                <div class="formulario-item">
                  <label for="nome" class="formulario-item-label">Nome</label>
                  <input type="text" v-model="usuario.nome" name="nome"
                    :class="{ 'invalid-field': verificarSeHaItem('nome', this.errors, 'field') }" />
                  <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('nome', this.errors, 'field')">
                    {{ verificarItemArray('nome', this.errors, 'field').message }}
                  </p>
                </div>

                <div class="formulario-item">
                  <label for="email" class="formulario-item-label">Email</label>
                  <input type="text" v-model="usuario.email" name="email"
                    :class="{ 'invalid-field': verificarSeHaItem('email', this.errors, 'field') }" />
                  <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('email', this.errors, 'field')">
                    {{ verificarItemArray('email', this.errors, 'field').message }}
                  </p>
                </div>

                <div class="formulario-item">
                  <label for="celular" class="formulario-item-label">Celular</label>
                  <input type="text" v-model="usuario.celular" v-mask="'(##) #####-####'" name="celular"
                    :class="{ 'invalid-field': verificarSeHaItem('celular', this.errors, 'field') }" />
                  <p class="input-erro-mensagem"
                    v-if="errors.length && verificarSeHaItem('celular', this.errors, 'field')">
                    {{ verificarItemArray('celular', this.errors, 'field').message }}
                  </p>
                </div>
              </div>

              <div class="d-md-flex">
                <div :class="{ 'f-25': isAdm() }" class="formulario-item">
                  <label for="dataNascimento" class="formulario-item-label">Data Nascimento</label>
                  <datepicker :class="{ 'invalid-field': verificarSeHaItem('dataNascimento', this.errors, 'field') }"
                    v-model="usuario.dataNascimento" :language="ptBR" />
                  <p class="input-erro-mensagem"
                    v-if="errors.length && verificarSeHaItem('dataNascimento', this.errors, 'field')">
                    {{ verificarItemArray('dataNascimento', this.errors, 'field').message }}
                  </p>
                </div>

                <div v-if="!isAdm()" class="formulario-item">
                  <label for="cargo" class="formulario-item-label">Cargo </label>
                  <multiselect :class="{ 'invalid-field-multiselect': verificarSeHaItem('cargo', this.errors, 'field') }"
                    v-model="usuario.cargo" label="descricao" track-by="id" :options="cargosFiltro" :searchable="false"
                    :multiple="false" placeholder="Selecione" select-label="Selecionar" deselect-label="Remover"
                    selected-label="Selecionado" :allow-empty="true" />
                  <p class="input-erro-mensagem" v-if="errors.length && verificarSeHaItem('cargo', this.errors, 'field')">
                    {{ verificarItemArray('cargo', this.errors, 'field').message }}
                  </p>
                </div>

                <div :class="{ 'f-25': isAdm() }" class="formulario-item">
                  <label for="cargo" class="formulario-item-label">Status</label>
                  <multiselect :class="{ 'invalid-field-multiselect': verificarSeHaItem('status', this.errors, 'field') }"
                    v-model="usuario.idStatus" label="descricao" track-by="descricao" :options="status"
                    :searchable="false" :multiple="false" placeholder="Selecione" select-label="Selecionar"
                    deselect-label="Remover" selected-label="Selecionado" :allow-empty="true" />
                  <p class="input-erro-mensagem"
                    v-if="errors.length && verificarSeHaItem('status', this.errors, 'field')">
                    {{ verificarItemArray('status', this.errors, 'field').message }}
                  </p>
                </div>

                <div :class="{ 'f-25': isAdm() }" class="formulario-item">
                  <label for="dataAtivacaoCadastro" class="formulario-item-label">Data Cadastro</label>
                  <datepicker
                    :class="{ 'invalid-field': verificarSeHaItem('dataAtivacaoCadastro', this.errors, 'field') }"
                    v-model="usuario.dataAtivacaoCadastro" :language="ptBR" disabled />
                  <p class="input-erro-mensagem"
                    v-if="errors.length && verificarSeHaItem('dataAtivacaoCadastro', this.errors, 'field')">
                    {{ verificarItemArray('dataAtivacaoCadastro', this.errors, 'field').message }}
                  </p>
                </div>

                <div v-if="!isAdm()" class="formulario-item">
                  <label for="totalPontos" class="formulario-item-label">Total pontos</label>
                  <input type="text" v-model="usuario.totalPontos" name="totalPontos" disabled />
                </div>

                <div v-if="!isAdm()" class="formulario-item">
                  <label for="totalPontos" class="formulario-item-label">E-mail ativado</label>
                  <datepicker v-model="usuario.dataConfirmacaoEmail" :language="ptBR" disabled v-if="usuario.emailConfirmado"/>
                  <input type="text" value="Não confirmado" name="totalPontos" disabled v-if="!usuario.emailConfirmado" />
                </div>

              </div>

              <div v-if="!isAdm()" class="d-md-flex">
                <div class="formulario-item">
                  <label for="cep" class="formulario-item-label">CEP</label>
                  <input type="text" v-model="usuario.cep" placeholder="" name="cep" v-mask="'##.###-###'"
                    @blur="buscaCep(usuario.cep, usuario)" />
                </div>

                <div class="formulario-item f-4">
                  <label for="endereco" class="formulario-item-label">Endereco</label>
                  <input type="text" v-model="usuario.endereco" name="endereco" />
                </div>

                <div class="formulario-item f-1">
                  <label for="numero" class="formulario-item-label">Numero</label>
                  <input type="text" v-model="usuario.numero" name="numero" />
                </div>

                <div class="formulario-item">
                  <label for="bairro" class="formulario-item-label">Bairro</label>
                  <input type="text" v-model="usuario.bairro" name="bairro" />
                </div>
              </div>

              <div v-if="!isAdm()" class="d-md-flex">
                <div class="formulario-item">
                  <label for="cidade" class="formulario-item-label">Cidade</label>
                  <input type="text" v-model="usuario.cidade" name="cidade" />
                </div>

                <div class="formulario-item f-2">
                  <label class="formulario-item-label">UF</label>
                  <multiselect v-model="usuario.uf" label="descricao" track-by="id" :options="regioesBr"
                    group-values="estados" group-label="descricao" :searchable="true" :multiple="false"
                    placeholder="Selecione" select-label="Selecionar" :allow-empty="false" deselect-label=" "
                    selected-label="Selecionado"><span slot="noResult">Item não encontrado</span>
                  </multiselect>
                </div>

                <div class="formulario-item">
                  <label for="complemento" class="formulario-item-label">Complemento</label>
                  <input type="text" v-model="usuario.complemento" name="complemento" />
                </div>

                <div class="formulario-item">
                  <label for="pontoReferencia" class="formulario-item-label">Ponto Referencia</label>
                  <input type="text" v-model="usuario.pontoReferencia" name="pontoReferencia" />
                </div>
              </div>

              <div class="container-btns">
                <button class="btn btn-cancelar"
                  @click.prevent="$router.push({ name: 'listar-usuarios' })">Voltar</button>
                <button class="btn btn-laranja" @click="chamaConfirmacao">Salvar</button>
              </div>
            </form>
          </tab>
          <tab v-if="!isAdm()" name="Autorizada">
            <form @submit.prevent class="formulario-container">
              <div class="d-md-flex">
                <div class="formulario-item medio">
                  <label class="formulario-item-label">Autorizada</label>
                  <multiselect label="descricao" track-by="descricao" v-model="usuario.autorizada.idsTipoAutorizada"
                    :options="tipo" :searchable="false" :allow-empty="false" placeholder="Selecione"
                    select-label="Selecionar" deselect-label="Selecionado" selected-label="Selecionado"
                    @input="idAutorizada = []" />
                </div>
                <div class="formulario-item medio">
                  <label class="formulario-item-label">{{ verificarLabel() }}</label>
                  <multiselect label="descricao" track-by="id" v-model="idAutorizada"
                    :options="verificarArray(usuario.autorizada.idsTipoAutorizada.id)" :searchable="true"
                    :allow-empty="false" placeholder="Selecione" select-label="Selecionar" deselect-label="Selecionado"
                    selected-label="Selecionado" :multiple="usuario.cargo.id >= 8 ? true : false"
                    :max="usuario.cargo.id == 10 ? 30 : 2">
                    <span slot="maxElements">
                      Só é possível adicionar 2 {{ verificarLabel() }}s
                    </span>
                  </multiselect>
                </div>
              </div>
            </form>
            <div class="container-btns">
              <button class="btn btn-cancelar" @click.prevent="$router.push({ name: 'listar-usuarios' })">Voltar</button>
              <button class="btn btn-laranja" @click="chamaConfirmacao">Salvar</button>
            </div>
            <section class="detalhes-usuario__lista-autorizada section_theme">
              <h2 class="lista-autorizada__titulo">Autorizada ativa</h2>
              <Tabela :colunas="colunasAutorizadas" :linhas="usuario.tabelaAutorizada" :paginacao="false" />
            </section>

          </tab>
          <tab v-if="!isAdm()" name="Histórico">
            <historico-usuario :cargos="cargos" :status="statusHistorico" />
          </tab>

          <tab v-if="!isAdm()" name="Ocorrências">
            <div class="container-btns">
              <button class="btn btn-laranja" @click="$router.push({ name: 'nova-ocorrencia' })">Criar
                nova
                ocorrência
              </button>
            </div>

            <section class="detalhes-usuario__lista-ocorrencias section_theme">
              <h2 class="lista-ocorrencias__titulo">Lista de ocorrências</h2>

              <div v-if="semDadosOcorrencia" class="tabela-sem-dados" key="Vazio">
                <p>Sem dados</p>
              </div>
              <Tabela v-else :colunas="colunas" :linhas="ocorrencias" :paginacao="true" :pagina="pagina"
                :totalPaginas="totalPaginasOcorrencias" :funcProximaPag="proximaPag" :funcAnteriorPag="anteriorPag" />
            </section>
          </tab>

          <tab v-if="!isAdm() && usuario.cargo.id != 10" name="Débito/Crédito de pontos">
            <debito-credito-pontos :cpfCnpj="usuario.cpf" :cargo="usuario.cargo"
              :autorizadasTitular="usuario.tabelaAutorizada" />
          </tab>

        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {
  detalhesUsuarioEditar,
  atualizarUsuario,
  buscaStatus,
  buscarOcorrenciaUsuario,
  detalhesUsuarioAdm,
  cadastrarUsuarioAdmin // Serve para editar tb
} from "@/services/usuarios";
import { listarSacMeioDeContato } from '@/services/sac'
import { listarAssistencias, listarTipoAutorizada } from "@/services/autorizadas";
import { listarCargos } from "@/services/espelho";
import { brEstados } from '@/utils/json'
import { ptBR } from "vuejs-datepicker/dist/locale";
import { functions, strings, requests, objects, masks } from '@/assets/js'
import HistoricoUsuario from "@/components/elementos/paginas/usuarios/HistoricoUsuario.vue";
import DebitoCreditoPontos from "@/components/elementos/paginas/usuarios/DebitoCreditoPontos.vue";
import Tab from "@/components/estrutura/tabs/_Tab";
import Tabs from "@/components/estrutura/tabs/_Tabs";
import Tabela from "@/components/Tabela/Tabela.vue";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import moment from "moment";
import store from "@/store";

export default {
  components: {
    Tab,
    Tabs,
    Tabela,
    Datepicker,
    Multiselect,
    HistoricoUsuario,
    DebitoCreditoPontos
  },
  name: "UsuariosEditar",
  data() {
    return {
      regioesBr: brEstados.ufRegiao,
      idAutorizada: [],
      usuario: {
        cargo: {},
        idStatus: "Bloqueado",
        autorizada: {
          idAutorizada: '',
          idsTipoAutorizada: ''
        },
        tabelaAutorizada: []
      },
      semDadosOcorrencia: false,
      pagina: 1,
      qtdItens: 10,
      totalPaginasOcorrencias: 0,
      objReferencia: {},
      colunasAutorizadas: [
        { key: "idAutorizada", titulo: 'Id', tamanho: 'pequeno', link: false },
        { key: "tipoAutorizada", titulo: 'Tipo', tamanho: 'medio-110', link: false },
        { key: "nomeFantasia", titulo: 'Nome Fantasia', tamanho: 'grande', link: false },
        { key: "autorizadaCodigoSap", titulo: 'Código SAP', tamanho: 'medio-130', link: false },
        { key: "autorizadaRegiao", titulo: 'Região', tamanho: 'medio-150', link: false },
        { key: "autorizadaGrupo", titulo: 'Grupo', tamanho: 'medio-150', link: false },
        { key: "autorizadaCNPJ", titulo: 'CNPJ', tamanho: 'medio-150', link: false },
      ],
      colunas: [
        { key: "status", titulo: 'Status', tamanho: 'medio-130', link: false },
        { key: "assunto", titulo: 'Assunto', tamanho: 'medio-130', link: false },
        { key: "ultimaInteracao", titulo: 'Última Interação', tamanho: 'medio-130', link: false },
        { key: "sacOcorrenciaMeioDeContatoId", titulo: 'Meio de Contato', tamanho: 'medio-130', link: false },
        { key: "nome", titulo: 'Nome', tamanho: 'grande', link: false },
        { key: "dataCadastro", titulo: 'Data Abertura', tamanho: 'medio-130', link: false },
        { key: "", titulo: '', tamanho: 'btns', link: true, func: this.detalhesOcorrencia },
      ],
      ocorrencias: [],
      ptBR: ptBR,
      errors: [],
      status: [],
      statusHistorico: [],
      selectAssistencias: [],
      selectFranquias: [],
      cargos: [],
      cargosFiltro: [],
      meiosContato: [],
      tipo: []
    };
  },

  watch: {
    "usuario.autorizada.idsTipoAutorizada"() {
      let idAssistencias = [1, 4, 7, 8, 9, 10];
      let idFranquias = [5, 6];
      if (this.usuario.autorizada.idsTipoAutorizada.id == 1) return this.cargosFiltro = this.cargos.filter(item => idAssistencias.find(i => i == item.id));
      if (this.usuario.autorizada.idsTipoAutorizada.id == 3) return this.cargosFiltro = this.cargos.filter(item => idFranquias.find(i => i == item.id));
    }
  },

  async mounted() {
    await Promise.all(this.isAdm() ? this.reqUserAdm() : this.reqUser())
    this.listarOcorrenciaUsuario();
    setTimeout(() => {
      this.dadosUsuario();
    }, 300)
  },
  methods: {
    reqUserAdm() {
      return [
        buscaStatus().then((resp) => {
          this.statusHistorico = resp.data;
          this.status = resp.data.filter(status => status.id != 2 && status.id != 7)
        })
      ]
    },
    reqUser() {
      return [
        ...this.reqUserAdm(),
        listarSacMeioDeContato().then(resp => {
          this.meiosContato = resp.data
        }),
        listarCargos().then((resp) => {
          this.cargos = resp.data;
        }),
        listarTipoAutorizada().then(resp => {
          this.tipo = resp.data.filter(tipo => tipo.id != 2 && tipo.id != 4)
        }),
        listarAssistencias(1).then((resp) => { // Assistencias
          this.selectAssistencias = resp.data;
        }),
        listarAssistencias(2).then((resp) => { // Franquias
          this.selectFranquias = resp.data;
        }),
      ]
    },
    verificarSeHaItem: functions.verificarSeHaItem,
    verificarItemArray: functions.verificarItemArray,
    buscaCep: requests.buscaCep,
    verificarLabel() {
      if (!this.isAdm()) return this.usuario.autorizada.idsTipoAutorizada.id == 1 ? "Assistência" : "Franquia"
    },
    verificarArray(idsTipoAutorizada) {
      if (!this.isAdm()) return idsTipoAutorizada == 1 ? this.selectAssistencias : this.selectFranquias
      else[]
    },
    isAdm() {
      return this.$route.params.perfil === '1' && true
    },
    detalhesOcorrencia(ocorrenciaId) {
      return `/sac/ocorrencia/${ocorrenciaId}`
    },
    proximaPag() {
      this.pagina++;
      this.listarOcorrenciaUsuario();
    },
    anteriorPag() {
      this.pagina--;
      this.listarOcorrenciaUsuario();
    },
    listarOcorrenciaUsuario() {
      buscarOcorrenciaUsuario(this.$route.params.id, this.pagina, this.qtdItens).then((resp) => {
        if (!resp.data.retorno.length > 0) {
          return this.semDadosOcorrencia = true;
        }
        this.totalPaginasOcorrencias = resp.data.paginacao.totalPaginas
        for (const key in resp.data.retorno) {
          const element = resp.data.retorno[key];
          this.ocorrencias.push(
            {
              id: element.id,
              status: element.sacStatus.descricao,
              assunto: element.sacAssunto.descricao,
              ultimaInteracao: element.ultimaIteracao,
              sacOcorrenciaMeioDeContatoId: this.verificarItemArray(element.sacOcorrenciaMeioDeContatoId, this.meiosContato, 'id').descricao,
              nome: element.usuarioParticipante.usuario.nome,
              dataCadastro: masks.formatarData(element.dataCadastro, 'DD/MM/YYYY'),
            }
          )
        }
      })
    },
    chamaConfirmacao() {
      if (!this.formValid()) { // Caso tenha campo inválido ou não preenchido
        return;
      } else if (objects.comparacaoObjForm(this.objReferencia, this.formData(false))) {
        // Para editar os dados deverão ser modificados
        store.commit("SET_MODAL", {
          ativado: true,
          mensagem: 'Não houve nenhuma mudança nos dados',
          tipo: "erro",
        })
      } else {
        // Modal
        const config = {
          ativado: true,
          tipo: "confirmacao",
          mensagem: `Tem certeza que deseja alterar os dados ?`,
          func: this.isAdm() ? this.atualizarDadosUsuarioAdm : this.atualizarDadosUsuario
        }
        store.commit("SET_MODAL_MENSAGEM", config);
      }
    },
    popularObjReferencia() {
      const obj = this.usuario
      for (const key in obj) {
        if (key === 'autorizada') { // Apenas usuarios site
          this.objReferencia['tipoAutorizada'] = obj.autorizada.idsTipoAutorizada
          this.objReferencia['idAutorizada'] = obj.autorizada.idAutorizada
        }
        else if (key === 'cargo') this.isAdm() ? 3 : this.objReferencia[key] = obj[key].id
        else if (key === 'idStatus') this.objReferencia[key] = obj[key].id
        else if (key === 'tabelaAutorizada') this.objReferencia[key] = 0 // Apenas usuarios site
        else if (key === 'uf') this.objReferencia[key] = obj[key] ? obj[key].descricao : ""
        else if (obj[key] === null) {
          this.usuario[key] = ""
          this.objReferencia[key] = ""
        }
        else this.objReferencia[key] = obj[key]
      }
    },
    dadosUsuario() {
      this.$route.params.perfil === "1" ? this.popularDadosUsuarioAdm() : this.popularDadosUsuarioSite()
    },
    popularDadosUsuarioAdm() {
      detalhesUsuarioAdm(this.$route.params.id).then(resp => {
        this.usuario = {
          cpf: resp.data.usuario.cpf,
          id: resp.data.usuario.idUsuario,
          idPerfilAcessoPainel: resp.data.usuario.idPerfilAcesso,
          nome: resp.data.usuario.nome,
          email: resp.data.usuario.email,
          dataNascimento: masks.formatarData(resp.data.usuario.dataNascimento, 'YYYY-MM-DD' + 'T00:01:00'),
          dataAtivacaoCadastro: resp.data.usuario.dataCadastro,
          idStatus: this.verificarItemArray(resp.data.usuario.status, this.status, 'descricao'),
          celular: resp.data.usuario.celular
        }
        this.popularObjReferencia()
      })
    },
    popularDadosUsuarioSite() {
      detalhesUsuarioEditar(this.$route.params.id, this.$route.params.perfil).then((resp) => {
        this.usuario = {
          idUsuario: resp.data.id,
          cpf: resp.data.cpf,
          nome: resp.data.nome,
          email: resp.data.email,
          dataNascimento: masks.formatarData(resp.data.dataNascimento, 'YYYY-MM-DD' + 'T00:01:00'),
          idStatus: this.verificarItemArray(resp.data.status, this.status, 'descricao'),
          dataAtivacaoCadastro: resp.data.dataAtivacaoCadastro,
          celular: resp.data.celular,
          cargo: this.verificarItemArray(resp.data.cargo, this.cargos, 'descricao'),
          totalPontos: resp.data.totalPontos,
          cep: resp.data.cep,
          emailConfirmado: resp.data.emailConfirmado,
          dataConfirmacaoEmail: resp.data.dataConfirmacaoEmail,
          endereco: resp.data.endereco,
          numero: resp.data.numero,
          bairro: resp.data.bairro,
          cidade: resp.data.cidade,
          uf: functions.verificarUf(resp.data.uf),
          complemento: resp.data.complemento ? resp.data.complemento : null,
          pontoReferencia: resp.data.pontoReferencia ? resp.data.pontoReferencia : null,
          autorizada: {
            idsTipoAutorizada: resp.data.idsTipoAutorizada[0] == 1 ? { id: 1, descricao: "Assistência" } : { id: 3, descricao: "Franquia" },
            // idAutorizada: this.verificarItemArray(resp.data.idsAutorizada, this.verificarArray(resp.data.idsTipoAutorizada[0]), 'id')
          },
          tabelaAutorizada: []
        }

        if (resp.data.status == "Bloqueado") this.usuario.idStatus = { id: 7, descricao: "Bloqueado" }
        if (resp.data.status == "Inativo") this.usuario.idStatus = { id: 2, descricao: "Inativo" }

        resp.data.idsAutorizada.map((item, index) => {
          this.usuario.tabelaAutorizada.push({
            nomeFantasia: resp.data.nomeFantasia[index],
            idAutorizada: resp.data.idsAutorizada[index],
            tipoAutorizada: resp.data.tiposAutorizada[index],
            autorizada: resp.data.autorizadas[index],
            autorizadaCodigoSap: resp.data.autorizadaCodigosSap[index],
            autorizadaRegiao: resp.data.autorizadaRegioes[index],
            autorizadaGrupo: resp.data.autorizadaGrupos[index],
            autorizadaCNPJ: resp.data.autorizadaCNPJs[index]
          })
          this.idAutorizada.push({
            codigoSAP: resp.data.autorizadaCodigosSap[index],
            descricao: resp.data.autorizadas[index],
            id: resp.data.idsAutorizada[index],
            tipo: resp.data.tiposAutorizada[index]
          })
        });

        this.usuario.autorizada.idAutorizada = this.idAutorizada;

        this.popularObjReferencia()

      });
    },
    formatarData(data) {
      return moment(data).format("YYYY-MM-DD");
    },
    formValid() {
      this.errors = [];

      if (!functions.verificarDadoValido(this.usuario.cpf)) {
        this.errors.push({ field: "cpf", message: "O CPF é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.usuario.nome)) {
        this.errors.push({ field: "nome", message: "O nome é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.usuario.email)) {
        this.errors.push({ field: "email", message: "O email é obrigatório" });
      }

      if (!functions.verificarDadoValido(this.usuario.celular)) {
        this.errors.push({
          field: "celular",
          message: "O celular é obrigatório",
        });
      }

      if (!this.isAdm()) {
        if (strings.removerCaracteresEspeciais(this.usuario.celular).length < 10 || strings.removerCaracteresEspeciais(this.usuario.celular).length > 11) {
          this.errors.push({
            field: "celular",
            message: "Formato inválido",
          });
        }
      }

      if (!functions.verificarDadoValido(this.usuario.dataNascimento)) {
        this.errors.push({
          field: "dataNascimento",
          message: "A data de nascimento é obrigatória",
        });
      }

      // Não é requirido para usuários adm
      if (!functions.verificarDadoValido(this.usuario.cargo) && !this.isAdm()) {
        this.errors.push({
          field: "cargo",
          message: "O cargo é obrigatório",
        });
      }

      if (!functions.verificarDadoValido(this.usuario.idStatus)) {
        this.errors.push({
          field: "status",
          message: "O status é obrigatório",
        });
      }

      if (!functions.verificarDadoValido(this.usuario.dataAtivacaoCadastro)) {
        this.errors.push({
          field: "dataAtivacaoCadastro",
          message: "Não pode alterar a data",
        });
      }

      if (this.errors.length > 0) {
        return false;
      }

      return true;
    },
    formData(sending) { // Sending é usado apenas para verificar repetições
      const data = new FormData();

      this.isAdm() ? data.append("id", this.usuario.id) : data.append("idUsuario", this.usuario.idUsuario);
      data.append("cpf", strings.removerCaracteresEspeciais(this.usuario.cpf));
      data.append("nome", this.usuario.nome);
      data.append("email", this.usuario.email);
      data.append("celular", strings.removerCaracteresEspeciais(this.usuario.celular));
      data.append("dataNascimento", masks.formatarData(this.usuario.dataNascimento, 'YYYY-MM-DD'));
      data.append("idStatus", this.usuario.idStatus ? this.usuario.idStatus.id : 0);
      !sending && data.append("dataAtivacaoCadastro", this.usuario.dataAtivacaoCadastro);
      this.isAdm() && data.append("idPerfilAcessoPainel", this.usuario.idPerfilAcessoPainel);
      !this.isAdm() && data.append("cargo", this.usuario.cargo ? this.usuario.cargo.id : 0); // Apenas usuário site
      !sending && !this.isAdm() && data.append("totalPontos", this.usuario.totalPontos); // Apenas usuário site
      !this.isAdm() && data.append("cep", this.usuario.cep ? strings.removerCaracteresEspeciais(this.usuario.cep) : ""); // Apenas usuário site
      !this.isAdm() && data.append("endereco", this.usuario.endereco && this.usuario.endereco != undefined ? this.usuario.endereco : ""); // Apenas usuário site
      !this.isAdm() && data.append("numero", this.usuario.numero ? this.usuario.numero : ""); // Apenas usuário site
      !this.isAdm() && data.append("bairro", this.usuario.bairro && this.usuario.bairro != undefined ? this.usuario.bairro : ""); // Apenas usuário site
      !this.isAdm() && data.append("cidade", this.usuario.cidade && this.usuario.cidade != undefined ? this.usuario.cidade : ""); // Apenas usuário site
      !this.isAdm() && data.append("uf", this.usuario.uf && this.usuario.uf.descricao != undefined && this.usuario.uf != undefined ? this.usuario.uf.descricao : ""); // Apenas usuário site
      !this.isAdm() && data.append("complemento", this.usuario.complemento ? this.usuario.complemento : ""); // Apenas usuário site
      !this.isAdm() && data.append("pontoReferencia", this.usuario.pontoReferencia ? this.usuario.pontoReferencia : ""); // Apenas usuário site
      !this.isAdm() && data.append("tipoAutorizada", this.usuario.autorizada.idsTipoAutorizada.id); // Apenas usuário site


      if (this.idAutorizada.length > 0) {
        this.idAutorizada && this.idAutorizada.map((item) => {
          data.append("IdsAutorizadas", item.id); // Apenas usuário site
        })
      } else {
        data.append("IdsAutorizadas", this.idAutorizada.id); // Apenas usuário site
      }

      !sending && !this.isAdm() && data.append("tabelaAutorizada", 0); // Apenas usuário site

      return data
    },
    atualizarDadosUsuario() {
      atualizarUsuario(this.formData(true));
    },
    atualizarDadosUsuarioAdm() {
      cadastrarUsuarioAdmin(this.formData(true));
    }
  },
};
</script>







<style src="vue-multiselect/dist/vue-multiselect.min.css" />

<style lang="scss">
.lista-autorizada {
  &__titulo {
    font-size: 24px;
    width: 100%;
    margin: 60px 15px 10px 18px;
    padding-bottom: 15px;
  }
}

.lista-ocorrencias {
  &__titulo {
    font-size: 24px;
    width: 100%;
    margin: 0px 15px 30px 14px;
  }
}
</style>
