<template>
    <div>
        <!-- <h2 class="h2">Histórico de alterações no cargo do usuário organizado pelas mudanças mais recentes.</h2> -->

        <div class="tabela-linha tabela-linha-titulo">
            <div class="tabela-linha-nome">
                <p>Alterado por</p>
            </div>
            <div class="tabela-linha-nome">
                <p>Cargo anterior</p>
            </div>
            <div class="tabela-linha-nome">
                <p>Cargo atual</p>
            </div>
            <div class="tabela-linha-data-final">
                <p>Data</p>
            </div>
        </div>
        <div class="tabela-linha tabela-linha-informacoes" v-for="(item, index) in dados" :key="index">
            <div class="tabela-linha-nome">
                <p>{{ item.alteradoPor }}</p>
            </div>
            <div class="tabela-linha-nome">
                <p>{{ converteIdCargo(item.cargoAnteriorId) }}</p>
            </div>
            <div class="tabela-linha-nome">
                <p>{{ converteIdCargo(item.cargoAtualId) }}</p>
            </div>
            <div class="tabela-linha-data-final">
                <p>{{ formatarData(item.dataOperacao) }}</p>
            </div>
        </div>

        <transition name="fade-left" mode="out-in">
            <div class="paginacao" v-if="totalPaginas">
                <button :disabled="pagina === 1" @click="anteriorPag">
                    Anterior
                </button>
                <p>{{ pagina }} - {{ totalPaginas }}</p>
                <button :disabled="pagina === totalPaginas" @click="proximaPag">
                    Próxima
                </button>
            </div>
        </transition>

    </div>
</template>


<script>
import moment from "moment";
import { buscarHistoricoCargo } from "@/services/usuarios";

export default {
    name: "HistoricoCargoUsuario",

    props: {
        cargos: Array
    },

    data() {
        return {
            pagina: 1,
            totalPaginas: 0,
            dados: []
        };
    },
    methods: {
        formatarData(data) {
            return moment(data).format("DD/MM/YYYY");
        },
        buscaHistoricoCargo() {
            buscarHistoricoCargo(this.$route.params.id, this.pagina).then((resp) => {
                this.dados = resp.data.retorno;
                this.totalPaginas = resp.data.paginacao.totalPaginas;
            })
        },
        converteIdCargo(id) {
            if (this.cargos.length == 0) return;
            let resFiltro = this.cargos.find(item => item.id == id);
            return resFiltro.descricao;
        },
        proximaPag() {
            this.pagina++;
            this.buscaHistoricoCargo();
        },
        anteriorPag() {
            this.pagina--;
            this.buscaHistoricoCargo();
        },
    },
    mounted() {
        this.buscaHistoricoCargo();
    }
}
</script>

<style lang="scss" scoped>
.h2 {
    font-size: 18px;
    padding: 0 8px;
    margin-bottom: 30px;
    font-weight: bold;
    color: $cinza-1;
}
</style>
