import axios from 'axios';
import store from '@/store';
import { _rotasAPI } from '@/services/_caminhosApi';
import { tokenExpirado } from '@/utils/tokenExpirado';

export function listarOrigemPontuacao() {
	return new Promise(resolve => {
		const token = localStorage.getItem("token");
		store.commit("SET_ADICIONAR_LOADING");
		axios
			.get(_rotasAPI().listarOrigemPontuacao, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			.then(resp => {
				resolve(resp);
			})
			.catch(erro => {
				tokenExpirado(erro, true);
			})
			.finally(() => store.commit("SET_REMOVER_LOADING"));
	});
}