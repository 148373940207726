<template>
    <form @submit.prevent="confirmaPontuacao" class="formulario-container">
        <div class="formulario-linha linha-tripla">
            <div class="formulario-item">
                <label class="formulario-item-label">Tipo de operação</label>
                <multiselect v-model="tipoOperacao" label="label" track-by="label" :options="selectOperacoes"
                    :searchable="false" :multiple="false" placeholder="Selecione a operação" select-label="Selecionar"
                    deselect-label="Remover" selected-label="Selecionado" :allow-empty="true"
                    @input="origemDebitoCredito = []" />
            </div>
            <div class="formulario-item">
                <label for="motivoAlteracao" class="formulario-item-label">Motivo da alteração</label>
                <input type="text" v-model="motivoAlteracao" placeholder="Digite o motivo" name="motivoAlteracao" />
            </div>
            <div class="formulario-item" v-if="cargo.id == 8">
                <label for="cnpjAutorizada" class="formulario-item-label">CNPJ Autorizada</label>
                <multiselect v-model="cnpjAutorizada" label="autorizadaCNPJ" track-by="autorizadaCNPJ"
                    :options="retornaCnpj()" :searchable="false" :multiple="false"
                    placeholder="Selecione a autorizada Autorizada" select-label="Selecionar" deselect-label=" "
                    selected-label="Selecionado" :allow-empty="false" />
            </div>
        </div>
        <div class="formulario-linha linha-tripla">
            <div class="formulario-item">
                <label for="descricao" class="formulario-item-label">Descrição</label>
                <input type="text" v-model="descricao" placeholder="Insira a descrição" name="descricao" />
            </div>
            <div class="formulario-item">
                <label for="pontuacao" class="formulario-item-label">Pontuação</label>
                <input type="text" v-model="pontuacao" placeholder="Insira a pontuação" name="pontuacao" />
            </div>
            <div class="formulario-item">
                <label class="formulario-item-label">Origem débito/crédito</label>
                <multiselect v-model="origemDebitoCredito" label="descricao" track-by="id" :options="verificatipoOperacao()"
                    :searchable="false" :multiple="false" placeholder="Escolha a origem" select-label="Selecionar"
                    deselect-label=" " selected-label="Selecionado" :allow-empty="true"
                    :disabled="tipoOperacao.length != 0 && tipoOperacao.value != 'D' ? false : true" />
            </div>
        </div>
        <div class="formulario-linha linha-tripla">
            <div class="formulario-item">
                <label class="formulario-item-label">Mês vigente</label>
                <multiselect v-model="mesVigente" label="label" track-by="label" :options="meses" :searchable="false"
                    :multiple="false" placeholder="Selecione o mês vigente" select-label="Selecionar"
                    deselect-label="Remover" selected-label="Selecionado" :allow-empty="true" />
            </div>
            <div class="formulario-item">
                <label class="formulario-item-label">Ano vigente</label>
                <multiselect v-model="anoVigente" label="label" track-by="label" :options="anos" :searchable="false"
                    :multiple="false" placeholder="Selecione o mês vigente" select-label="Selecionar"
                    deselect-label="Remover" selected-label="Selecionado" :allow-empty="true" />
            </div>
            <div class="formulario-item">
                <label class="formulario-item-label">Data de cadastro</label>
                <datepicker v-model="dataCadastro" :language="ptBR" placeholder="Selecione a data de cadastro" />
            </div>
        </div>
        <div class="container-btns margin-top">
            <button type="submit" class="btn btn-laranja" :disabled="$v.$invalid">Salvar</button>
        </div>
    </form>
</template>


<script>
import moment from "moment";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import { required } from "vuelidate/lib/validators";
import { pontuacaoUsuario } from "@/services/usuarios";
import { listarOrigemPontuacao } from "@/services/extrato"
import { ptBR } from "vuejs-datepicker/dist/locale";

export default {
    name: "DebitoCreditoPontos",

    components: {
        Datepicker,
        Multiselect
    },

    props: ['cargo', 'cpfCnpj', 'autorizadasTitular'],

    data() {
        return {
            tipoOperacao: [],
            descricao: "",
            pontuacao: "",
            mesVigente: "",
            anoVigente: "",
            dataCadastro: "",
            motivoAlteracao: "",
            origemDebitoCredito: {},
            cnpjAutorizada: "",
            errors: [],
            ptBR: ptBR,
            selectOperacoes: [
                { value: "D", label: 'Débito' },
                { value: "C", label: 'Crédito' }
            ],
            meses: [
                { value: '01', label: "Janeiro" },
                { value: '02', label: "Fevereiro" },
                { value: '03', label: "Março" },
                { value: '04', label: "Abril" },
                { value: '05', label: "Maio" },
                { value: '06', label: "Junho" },
                { value: '07', label: "Julho" },
                { value: '08', label: "Agosto" },
                { value: '09', label: "Setembro" },
                { value: '10', label: "Outubro" },
                { value: '11', label: "Novembro" },
                { value: '12', label: "Dezembro" }
            ],
            anos: [],
            listaDebitos: [],
            listaCreditos: [],
            submitStatus: null
        };
    },
    validations() {
        if (this.cargo.id == 8) {
            return {
                tipoOperacao: { required },
                descricao: { required },
                pontuacao: { required },
                mesVigente: { required },
                anoVigente: { required },
                dataCadastro: { required },
                motivoAlteracao: { required },
                cnpjAutorizada: { required }
            }
        } else {
            return {
                tipoOperacao: { required },
                descricao: { required },
                pontuacao: { required },
                mesVigente: { required },
                anoVigente: { required },
                dataCadastro: { required },
                motivoAlteracao: { required },
            }
        }
    },
    methods: {
        retornaCnpj() {
            if (this.autorizadasTitular.lengt < 1) return;

            let listaFormatada = [];
            this.autorizadasTitular.forEach((item) => {
                item.autorizadaCNPJ = item.autorizadaCNPJ.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
                listaFormatada.push(item);
            });

            return listaFormatada
        },
        verificaCargo() {
            return this.cargo.id == 8 ? required : null;
        },
        verificatipoOperacao() {
            if (this.tipoOperacao.value == 'C') return this.listaCreditos;
            if (this.tipoOperacao.value == 'D') {
                this.origemDebitoCredito = this.listaDebitos[0];
                return [this.origemDebitoCredito];
            }
            return this.listaDebitos
        },
        confirmaPontuacao() {
            const config = {
                ativado: true,
                tipo: "confirmacao",
                mensagem: `Tem certeza que deseja cadastrar novo usuário?`,
                func: this.salvarPontuacao
            }
            this.$store.commit("SET_MODAL_MENSAGEM", config);
        },
        salvarPontuacao() {
            let cnpjCpf = this.cargo.id == 8 ? this.cnpjAutorizada.autorizadaCNPJ.replace(/[^\d]/g, "") : this.cpfCnpj.replace(/[^\d]/g, "");
            let dadosPontuacao = {
                "cpfCnpj": cnpjCpf,
                "mes": this.mesVigente.value,
                "motivo": this.motivoAlteracao,
                "ano": this.anoVigente.value,
                "pontuacao": this.pontuacao.replace(",", "."),
                "origemPontuacaoId": this.origemDebitoCredito.id,
                "descricao": this.descricao,
                "data": this.dataCadastro,
                "operacao": this.tipoOperacao.value
            }
            pontuacaoUsuario(dadosPontuacao).then(() => {
                this.tipoOperacao = [];
                this.descricao = "";
                this.pontuacao = "";
                this.mesVigente = "";
                this.anoVigente = "";
                this.dataCadastro = "";
                this.motivoAlteracao = "";
                this.origemDebitoCredito = "";
                this.cnpjAutorizada = "";
            });

        },
        defineAnos() {
            let anoInicio = 2023;
            let anosTotal = moment().year() - anoInicio;
            for (let i = 0; i <= anosTotal; i++) {
                this.anos.push({ value: anoInicio + i, label: anoInicio + i })
            }
        },
        obterOrigemPontuacao() {
            listarOrigemPontuacao().then((resp) => {
                resp.data.forEach(item => {
                    if (item.id == 10) this.listaDebitos.push(item);
                    if (item.id == 5 || item.id == 9 || item.id == 11) this.listaCreditos.push(item);
                });
            })
        }
    },
    mounted() {
        this.defineAnos();
        this.obterOrigemPontuacao();
    }
}
</script>

<style lang="scss" scoped></style>
