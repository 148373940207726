<template>
    <div>
        <!-- <h2 class="h2">Histórico de alterações nas autorizadas do usuário, organizado pelas mudanças mais recentes.</h2> -->

        <div class="tabela-linha tabela-linha-titulo">
            <div class="tabela-linha-porcentagem">
                <p>Autorizada</p>
            </div>
            <div class="tabela-linha-cpf">
                <p>Código SAP</p>
            </div>
            <div class="tabela-linha-data-final">
                <p>Data</p>
            </div>
            <div class="tabela-linha-data-final">
                <p>Ativo</p>
            </div>
        </div>

        <div class="tabela-linha tabela-linha-informacoes" v-for="(item, index) in dados" :key="index">
            <div class="tabela-linha-porcentagem">
                <p>{{ item.nomeFantasia }}</p>
            </div>
            <div class="tabela-linha-cpf">
                <p>{{ item.codigoSAP }}</p>
            </div>
            <div class="tabela-linha-data-final">
                <p>{{ formatarData(item.dataAtualizacao) }}</p>
            </div>
            <div class="tabela-linha-data-final">
                <p>{{ item.ativo ? 'Sim' : 'Não' }}</p>
            </div>
        </div>

        <transition name="fade-left" mode="out-in">
            <div class="paginacao" v-if="totalPaginas">
                <button :disabled="pagina === 1" @click="anteriorPag">
                    Anterior
                </button>
                <p>{{ pagina }} - {{ totalPaginas }}</p>
                <button :disabled="pagina === totalPaginas" @click="proximaPag">
                    Próxima
                </button>
            </div>
        </transition>

    </div>
</template>


<script>
import moment from "moment";
import { buscarHistoricoAutorizadas } from "@/services/usuarios";

export default {
    name: "HistoricoAcessoUsuario",

    data() {
        return {
            pagina: 1,
            totalPaginas: 0,
            dados: []
        };
    },
    methods: {
        formatarData(data) {
            return moment(data).format("DD/MM/YYYY");
        },
        buscaHistoricoAutorizadas() {
            buscarHistoricoAutorizadas(this.$route.params.id, this.pagina).then((resp) => {
                this.dados = resp.data.retorno;
                this.totalPaginas = resp.data.paginacao.totalPaginas;
            })
        },
        proximaPag() {
            this.pagina++;
            this.buscaHistoricoAutorizadas();
        },
        anteriorPag() {
            this.pagina--;
            this.buscaHistoricoAutorizadas();
        },
    },
    mounted() {
        this.buscaHistoricoAutorizadas();
    }
};
</script>

<style lang="scss" scoped>
.tabela-linha.tabela-linha-informacoes > div p {
    width: 300px;
}
.h2 {
    font-size: 18px;
    padding: 0 8px;
    margin-bottom: 30px;
    font-weight: 600;
    color: $cinza-1;
}
</style>
