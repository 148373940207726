<template>
    <tabs class="tabela-informacoes">
        <tab name="Cargo" :selected="true">
            <historico-cargo :cargos="cargos"/>
        </tab>
        <tab name="Status">
            <historico-status :status="status"/>
        </tab>
        <tab name="Autorizada">
            <historico-autorizada />
        </tab>
        <!-- <tab name="Acesso">
            <historico-acesso />
        </tab> -->
    </tabs>
</template>


<script>
import Tab from "@/components/estrutura/tabs/_Tab";
import Tabs from "@/components/estrutura/tabs/_Tabs";
import HistoricoCargo from "./HistoricoUsuarioCargo.vue";
import HistoricoStatus from "./HistoricoUsuarioStatus.vue";
import HistoricoAutorizada from "./HistoricoUsuarioAutorizada.vue";
// import HistoricoAcesso from "./HistoricoUsuarioAcesso.vue";

export default {
    components: {
        Tab,
        Tabs,
        HistoricoCargo,
        HistoricoStatus,
        HistoricoAutorizada,
        // HistoricoAcesso
    },
    
    props: {
        cargos: Array,
        status: Array
    }, 

    name: "HistoricoUsuario",
    data() {
        return {
        };
    },
};
</script>

<style lang="scss"></style>
